<!-- eslint-disable @typescript-eslint/no-unsafe-assignment -->
<script setup lang="ts">
import {
  computed, defineAsyncComponent, ref, watchPostEffect,
} from 'vue';
import AppLayoutDefaultHeader from 'UiKit/components/TheHeader/AppLayoutDefaultHeader.vue';
import AppLayoutDefaultFooter from 'UiKit/components/TheFooter/AppLayoutDefaultFooter.vue';
import HelpUkraineBanner from '@/layouts/HelpUkraine/components/HelpUkraineBanner.vue';
import { useData, useRoute } from 'vitepress';
import { useWindowScroll } from '@vueuse/core';

const ViewHome = defineAsyncComponent(() => import('@/layouts/Home/ViewHome.vue'));
const ViewGetStarted = defineAsyncComponent(() => import('@/layouts/ViewGetStarted.vue'));
const ViewResourceCenter = defineAsyncComponent(() => import('@/layouts/ViewResourceCenter.vue'));
const ViewResourceCenterPost = defineAsyncComponent(() => import('@/layouts/ViewResourceCenterPost.vue'));
const ViewAuthorPage = defineAsyncComponent(() => import('@/layouts/ViewAuthorPage.vue'));
const ViewProducts = defineAsyncComponent(() => import('@/layouts/ViewProducts.vue'));
const ViewHowWeCanAssist = defineAsyncComponent(() => import('@/layouts/ViewHowWeCanAssist.vue'));
const ViewHelpUkraine = defineAsyncComponent(() => import('@/layouts/HelpUkraine/ViewHelpUkraine.vue'));
const ViewDigitalEcosystem = defineAsyncComponent(() => import('@/layouts/ViewDigitalEcosystem.vue'));
const ViewDevelopmentStages = defineAsyncComponent(() => import('@/layouts/ViewDevelopmentStages.vue'));
const ViewDevelopment = defineAsyncComponent(() => import('@/layouts/ViewDevelopment.vue'));
const ViewCtoAsAService = defineAsyncComponent(() => import('@/layouts/ViewCtoAsAService.vue'));
const ViewAboutUs = defineAsyncComponent(() => import('@/layouts/ViewAboutUs.vue'));
const ViewCaseStudies = defineAsyncComponent(() => import('@/layouts/ViewCaseStudies.vue'));
const ViewCaseStudiesSingle = defineAsyncComponent(() => import('@/layouts/ViewCaseStudiesSingle.vue'));
const ViewCareersSingle = defineAsyncComponent(() => import('@/layouts/ViewCareerSingle.vue'));
const ViewCareers = defineAsyncComponent(() => import('@/layouts/ViewCareers.vue'));
const ViewUIKitSingle = defineAsyncComponent(() => import('@/layouts/ViewUIKitSingle.vue'));
const ViewWikiSingle = defineAsyncComponent(() => import('@/layouts/ViewWikiSingle.vue'));
const ViewErrors = defineAsyncComponent(() => import('@/layouts/ViewErrorNotFound404.vue'));

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VToaster = defineAsyncComponent(() => import(
  'UiKit/components/VToaster/VToaster.vue'
));

const { page, frontmatter } = useData();
const route = useRoute();
const { y } = useWindowScroll();


const isFixed = ref(false);

const showFooter = computed(() => {
  const routes = ['/get-started'];
  return !routes.includes(String(route.path));
});


watchPostEffect(() => {
  if (y.value > 0) {
    isFixed.value = true;
  } else {
    isFixed.value = false;
  }
});
</script>


<template>
  <div class="AppLayoutDefault app-layout-default">
    <header
      class="app-layout-default__header"
      :class="{ fixed: isFixed }"
    >
      <AppLayoutDefaultHeader />
    </header>

    <main class="app-layout-default__main is--markdown">
      <ViewErrors
        v-if="page.isNotFound"
      />
      <ViewHome v-else-if="frontmatter.layout === 'home'" />
      <ViewGetStarted
        v-else-if="frontmatter.layout === 'get-started'"
      />
      <ViewResourceCenter
        v-else-if="frontmatter.layout === 'resource-center'"
      />
      <ViewResourceCenterPost
        v-else-if="frontmatter.layout === 'resource-center-single'"
      />
      <ViewAuthorPage
        v-else-if="frontmatter.layout === 'resource-center-author'"
      />
      <ViewProducts
        v-else-if="frontmatter.layout === 'products'"
      />
      <ViewHowWeCanAssist
        v-else-if="frontmatter.layout === 'how-can-we-assist'"
      />
      <ViewHelpUkraine
        v-else-if="frontmatter.layout === 'help-ukraine'"
      />
      <ViewDigitalEcosystem
        v-else-if="frontmatter.layout === 'digital-ecosystem'"
      />
      <ViewDevelopmentStages
        v-else-if="frontmatter.layout === 'development-stages'"
      />
      <ViewDevelopment
        v-else-if="frontmatter.layout === 'development-single'"
      />
      <ViewCtoAsAService
        v-else-if="frontmatter.layout === 'cto-as-a-service'"
      />
      <ViewAboutUs
        v-else-if="frontmatter.layout === 'about-us'"
      />
      <ViewCaseStudiesSingle
        v-else-if="frontmatter.layout === 'case-studies-single'"
      />
      <ViewCaseStudies
        v-else-if="frontmatter.layout === 'case-studies'"
      />
      <ViewCareersSingle
        v-else-if="frontmatter.layout === 'careers-single'"
      />
      <ViewCareers
        v-else-if="frontmatter.layout === 'careers'"
      />
      <ViewWikiSingle
        v-else-if="frontmatter.layout === 'wiki-single'"
      />
      <ViewUIKitSingle
        v-else-if="frontmatter.layout === 'uikit-single'"
      />
      <div
        v-else
        class="is--content is--container is--page"
      >
        <Content />
        <!-- if we use somewhere Content it is mandatory to wrap it in container with class 'is--markdown'-->
      </div>
    </main>

    <template v-if="showFooter">
      <HelpUkraineBanner />
      <footer class="app-layout-default__footer">
        <AppLayoutDefaultFooter />
      </footer>
    </template>

    <Notifications
      position="top right"
      group="transaction"
    >
      <template #body="props">
        <VToaster
          v-bind="props.item"
          :duration="props.item.data.duration"
          @close="props.close"
        />
      </template>
    </Notifications>
  </div>
</template>


<style lang="scss">
.app-layout-default {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    height: $header-height;
    transition: all .12s ease-in-out;

    &.fixed {
      position: fixed;
      top: 0;
      background: $white;
      box-shadow: $box-shadow-small;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 1;
    background: $white;
    min-height: 100vh;
  }

  .is--content {
    width: 100%;
  }

  &__footer {
    width: 100%;
    z-index: 1;
    margin-top: 0 !important;
  }
}
</style>
