import '@/assets/index.sass';
import type { Theme } from 'vitepress';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import { createPinia } from 'pinia';
import VueNotification from '@kyvg/vue3-notification';
import VTooltip from 'UiKit/components/VTooltip/VTooltip.vue';
import VButton from 'UiKit/components/VButton/VButton.vue';
import VSvgIcon from 'UiKit/components/VSvgIcon/VSvgIcon.vue';
import VTag from 'UiKit/components/VTag/VTag.vue';
import VHighlight from 'UiKit/components/Ads/VHighlight.vue';
import VSectionCaseStudiesMain from 'UiKit/components/Section/VSectionCaseStudiesMain.vue';
import VSliderWithCardBlueLine from 'UiKit/components/Sliders/VSliderWithCardBlueLine.vue';
import VWeHelp from 'UiKit/components/Ads/VWeHelp.vue';
import VCardCheckmarked from 'UiKit/components/Cards/VCardCheckmarked.vue';

export default {
  Layout: AppLayoutDefault,
  enhanceApp({ app, router, siteData }) {
    const pinia = createPinia();
    app.use(pinia)
    app.use(VueNotification)
    app.component('VTooltip', VTooltip)
    app.component('VButton', VButton)
    app.component('VSvgIcon', VSvgIcon)
    app.component('VTag', VTag)
    app.component('VHighlight', VHighlight)
    app.component('VSectionCaseStudiesMain', VSectionCaseStudiesMain)
    app.component('VSliderWithCardBlueLine', VSliderWithCardBlueLine)
    app.component('VWeHelp', VWeHelp)
    app.component('VCardCheckmarked', VCardCheckmarked)
  }
} satisfies Theme
