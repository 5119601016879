export const env = {
  env: import.meta.env?.VITE_ENV as string,
  robotsNoIndex: import.meta.env?.VITE_robotsNoIndex as string,
  FRONTEND_URL: import.meta.env?.VITE_FRONTEND_URL as string,
  CHATBOT_URL: import.meta.env?.VITE_CHATBOT_URL as string,
  HUBSPOTFORM: import.meta.env?.VITE_HUBSPOTFORM as string,
  HUBSPOTPORTAL_ID: import.meta.env?.VITE_HUBSPOTPORTAL_ID as string,
  HUBSPOT_FORM_ID_RECEIVE_LATEST_NEWS: import.meta.env?.VITE_HUBSPOT_FORM_ID_RECEIVE_LATEST_NEWS as string,
  SEGMENT_KEY: import.meta.env?.VITE_SEGMENT_KEY as string,
  GTM: import.meta.env?.VITE_GTM as string,
  title: 'Webdevelop.PRO',
  description: 'Accelerate project launches with our pre-engineered components and expertise.',
  keywords: ['development', 'web development'],
  author: 'Webdevelop.pro',
  email: 'manager@webdevelop.pro',
  phone: '+1 609 733 7724',
  bookACall: import.meta.env?.VITE_bookACall as string,
  sendAMessage: import.meta.env?.VITE_sendAMessage as string,
  leaveEmail: import.meta.env?.VITE_leaveEmail as string,
  locale: 'en_US',
};
