
import { data as allPages } from '@/store/all.data';
import { IFrontmatter } from 'UiKit/types/types';
import { filterAndSortByOrderElements } from 'UiKit/helpers/allData';
import { formatItemsBySubfolder, formatMenu } from 'UiKit/helpers/menu';

export type MenuItem = {
  link?: string;
  text: string;
  frontmatter?: IFrontmatter;
  children?: MenuItem[][];
  card?: {
    title?: string;
    text?: string;
    background?: string;
    url?: string;
  };
}

const productsLocal = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'products');
const developmentsLocal = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'development-single');
const digitalEcosystemsLocal = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'digital-ecosystem');
const useCasesLocal = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'case-studies-single');

const useCases = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'case-studies');
const cto = filterAndSortByOrderElements(allPages as IFrontmatter[], 'slug', 'cto-as-a-service');
const aboutUs = filterAndSortByOrderElements(allPages as IFrontmatter[], 'layout', 'about-us');
const preBuildSolutions = filterAndSortByOrderElements(allPages as IFrontmatter[], 'slug', 'pre-built-solutions');
const careers = filterAndSortByOrderElements(allPages as IFrontmatter[], 'slug', 'careers');
const getStarted = filterAndSortByOrderElements(allPages as IFrontmatter[], 'slug', 'get-started');
const blog = filterAndSortByOrderElements(allPages as IFrontmatter[], 'slug', 'resource-center');

export const MENU_HEADER_RIGHT: MenuItem[] = [
  {
    text: 'Platform',
    children: formatItemsBySubfolder(productsLocal),
    card: {
      title: preBuildSolutions[0].title,
      text: 'No Code & Pre-built Solutions enable rapid deployment with no risks.',
      background: '/images/menu/products-bg.svg',
      url: preBuildSolutions[0].url,
    },
  },
  {
    text: useCases[0].title,
    children: formatItemsBySubfolder(useCasesLocal),
    card: {
      title: aboutUs[0].title,
      text: 'Shaping the future of software development.',
      background: '/images/menu/use-case-bg.svg',
      url: aboutUs[0].url,
    },
  },
  {
    text: 'Digital Ecosystem',
    children: formatItemsBySubfolder(digitalEcosystemsLocal),
    card: {
      title: useCases[0].title,
      text: 'Explore more possibilities for your idea by use case.',
      background: '/images/menu/digital-bg.svg',
      url: useCases[0].url,
    },
  },
  {
    link: cto[0].url,
    text: cto[0].title,
  },
];

export const MENU_FOOTER: MenuItem[] = [
  {
    text: 'Platform',
    children: formatMenu(productsLocal),
  },
  {
    text: 'Company',
    children: [
      {
        link: aboutUs[0].url,
        text: aboutUs[0].title,
      },
      // {
      //   link: ourProcess[0].url,
      //   text: ourProcess[0].title,
      // },
      {
        link: useCases[0].url,
        text: useCases[0].title,
      },
      // {
      //   link: '/resource-center',
      //   text: 'Learning Center',
      // },
      // {
      //   link: ROUTE_RESOURCE_CENTER,
      //   text: 'Press & Awards',
      // },
      {
        link: careers[0].url,
        text: 'Careers',
      },
      {
        link: getStarted[0].url,
        text: 'Contact Us',
      },
    ],
  },
  {
    text: 'Development',
    children: [
      ...formatMenu(developmentsLocal),
      {
        link: blog[0].url,
        text: blog[0].title,
      },
    ],
  },
];
